<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="SQL Statements" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <mex-card title="SQL Statements" icon="mdi-format-list-text" cardClass="foreground">
          <mex-data-table
            :data="sqlStatements"
            :headers="getTableHeaders"
            tableClass="foreground"
            :footer-props="{
              'items-per-page-options': getTablePagination.rowsPerPage,
            }"
            :items-per-page="getTablePagination.defaultRowsPerPage"
          >
            <template v-slot:item.sqlStatement.statement="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-truncate" v-bind="attrs" v-on="on" style="max-width:50vh;">
                    {{ item.sqlStatement.statement }}
                  </div>
                </template>
                <span> {{ item.sqlStatement.statement }} </span>
              </v-tooltip>
            </template>
            <template v-slot:item.sqlStatement.description="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-truncate" v-bind="attrs" v-on="on" style="max-width:50vh;">
                    {{ item.sqlStatement.description }}
                  </div>
                </template>
                <span> {{ item.sqlStatement.description }} </span>
              </v-tooltip>
            </template>
            <template v-slot:item.sqlStatement.active="{ item }">
              <v-tooltip v-if="item.sqlStatement.active" color="success" right>
                <template v-slot:activator="{ on, attrs }">
                  <mex-status-lamp v-bind="attrs" v-on="on" color="success" />
                </template>
                <span> SQL statement is active </span>
              </v-tooltip>
              <v-tooltip v-else color="warning" right>
                <template v-slot:activator="{ on, attrs }">
                  <mex-status-lamp v-bind="attrs" v-on="on" color="warning" />
                </template>
                <span> SQL statement is inactive </span>
              </v-tooltip>
            </template>
            <template v-slot:item.assignedReceived="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">{{ item.assignedClinics }} / {{ item.receivedResults }}</div>
                </template>
                <span>Assigned to {{ item.assignedClinics }} clinics / {{ item.receivedResults }} results received</span>
              </v-tooltip>
            </template>
            <template v-slot:item.edit="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn x-small class="white--text" v-bind="attrs" v-on="on" >
                    <v-icon>mdi-triangle-small-down</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item dense @click="downloadSqlStatementResults(item, 1, 'csv')">
                    Download as CSV in one file
                  </v-list-item>
                  <v-list-item dense @click="downloadSqlStatementResults(item, 0, 'csv')">
                    Download as CSV in separate files
                  </v-list-item>
                  <v-list-item dense @click="downloadSqlStatementResults(item, 1, 'json')">
                    Download as JSON in one file
                  </v-list-item>
                  <v-list-item dense @click="downloadSqlStatementResults(item, 0, 'json')">
                    Download as JSON in separate files
                  </v-list-item>
                </v-list>
              </v-menu>
              <mex-btn icon="mdi-database-edit" tooltip="Edit Sql Statement" iconOnly @click="goToSqlStatement(item.sqlStatement.SqlStatementID)" />
              <mex-btn icon="mdi-delete-sweep-outline" tooltip="Delete only the Sql Statement assignments and all its results" iconOnly @click="deleteSqlStatementAssignments(item)" />
              <mex-btn icon="mdi-delete-outline" iconOnly tooltip="Delete Sql Statement with all its assignments and results" @click="deleteSqlStatement(item)" />
            </template>
          </mex-data-table>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>
                Confirm Deletion
              </v-card-title>
              <v-card-text class="text-h5">
                {{ deleteText }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <mex-btn content="Cancel" text @click="closeDelete"></mex-btn>
                <mex-btn content="Delete" text @click="confirmDelete"></mex-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </mex-card>
        <v-overlay  v-if="isDownloading" >
          <mex-sperm-spinner :spinnerText="getSpinnerText" />
        </v-overlay>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SqlStatementsService from '../../services/sqlStatements.service';
import requiredPermissions from '../../requiredPermissions';
import MexBtn from "../../components/MedITEX_Vue_Components/MexComponents/MexButton";
import tablePagination from '../../config/tablePagination.config';
import { downloadFile, downloadZip } from '../../functions/downloadFiles'
import MexSpermSpinner from "../../components/MedITEX_Vue_Components/MexComponents/MexSpermSpinner";

export default {
  name: 'SqlStatements',
  components: { MexSpermSpinner, MexBtn },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    getTableHeaders() {
      if (!this.writeSQL) {
        return this.sqlStatementTableHeaders.filter((x) => {
          return x.value !== 'edit';
        });
      }
      return this.sqlStatementTableHeaders;
    },
    writeSQL() {
      return this.getUserPermissions.includes('write_SQL Broadcaster');
    },
    getTablePagination() {
      return tablePagination;
    },
    getSpinnerText() {
      return this.isDownloadingText
    }
  },
  data() {
    return {
      // Visualization
      isDownloading: false,
      isDownloadingText: '',
      //data
      sqlStatements: [],
      hasPermission: false,
      deleteText: '',
      dialogDelete: false,
      deleteComplete: true,
      toDeleteSqlStatementId: null,
      sqlStatementTableHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'sqlStatement.name',
        },
        {
          text: 'Statement',
          align: 'start',
          sortable: true,
          value: 'sqlStatement.statement',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: true,
          value: 'sqlStatement.description',
        },
        {
          text: 'Assigned / Received',
          align: 'center',
          sortable: false,
          value: 'assignedReceived',
        },
        {
          text: 'Active',
          align: 'center',
          sortable: false,
          value: 'sqlStatement.active',
        },
        {
          text: 'Edit',
          align: 'center',
          sortable: false,
          value: 'edit',
        },
      ],
    };
  },
  created() {
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.sqlBroadcaster, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.getSqlStatements();
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  },
  methods: {
    goToSqlStatement(sqlStatementID) {
      this.$router.push({ name: 'SqlStatementEditor', params: { id: sqlStatementID } });
    },
    getSqlStatements() {
      SqlStatementsService.getSqlStatements()
        .then((sqlStatementResponse) => {
          this.sqlStatements = sqlStatementResponse.data;
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
        });
    },
    deleteSqlStatement(item) {
      this.toDeleteSqlStatementId = item.sqlStatement.SqlStatementID;
      this.deleteComplete = true;
      this.deleteText = "Do you really want to delete this Sql Statement? It will be irrevocably deleted with all assignments and results";
      this.dialogDelete = true;
    },
    deleteSqlStatementAssignments(item) {
      this.toDeleteSqlStatementId = item.sqlStatement.SqlStatementID;
      this.deleteComplete = false;
      this.deleteText = "Do you really want to delete all assignments and results from this Sql Statement?";
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    confirmDelete() {
      if (this.deleteComplete) {
        SqlStatementsService.deleteSqlStatement(this.toDeleteSqlStatementId)
          .then(() => {
            this.$toast.success("Deleted successfully");
            this.closeDelete();
            this.getSqlStatements();
          })
          .catch((err) => {
            this.$toast.error(`Error while deleting: ${err.response.data.message}`);
            this.closeDelete();
          })
      } else {
        SqlStatementsService.deleteSqlStatementAssignments(this.toDeleteSqlStatementId)
          .then(() => {
            this.$toast.success("Deleted successfully");
            this.closeDelete();
            this.getSqlStatements();
          })
          .catch((err) => {
            this.$toast.error(`Error while deleting: ${err.response.data.message}`);
            this.closeDelete();
          })
      }
    },

    downloadSqlStatementResults(item, downloadType, fileType) {
      this.isDownloading = true;
      this.isDownloadingText = 'Saving the data';
      let assignedClinics = []
      SqlStatementsService.getSqlStatement(item.sqlStatement.SqlStatementID).then(clinics =>{
        assignedClinics = clinics.data.assignedClinics.map(clinic => clinic.ClinicID)
        SqlStatementsService.getSqlStatementResults(item.sqlStatement.name, assignedClinics, fileType, downloadType)
          .then((sqlStatementResult) => {
            if (downloadType == 1) {    //downloadType 1 = Merge all results into 1 file
              downloadFile(sqlStatementResult.data.result, sqlStatementResult.data.sqlStatementName, fileType).then(() => {
                this.isDownloading = false
                }
              )
            } else {    //put data for clinics in separate files and download as zipped folder
              let filenamesAndData = []
              for (let sqlStatement of sqlStatementResult.data) {
                filenamesAndData.push(
                  {
                    'name': sqlStatement.clinicName,
                    'content': sqlStatement.result
                  }
                );
              }
              downloadZip(filenamesAndData, item.sqlStatement.name, fileType).then(() => {
                this.isDownloading = false
                }
              ).catch((err) => {
                this.isDownloading = false;
                this.$toast.error(err.response.data);
              });
            }
          }).catch((err) => {
            this.isDownloading = false;
            this.$toast.error(err.response.data);
          });
      }).catch((err) => {
          this.isDownloading = false;
          this.$toast.error(err.response.data);
        });
    }
  },

};
</script>

<style scoped></style>
